// these names are referred in the "store/index.ts" file
export default [
    "Auth.authUser",
    "Auth.apiAccessToken",
    "Auth.accessRegistration",
    "Auth.accessLogin",
    "Auth.workspaceList",
    "Auth.isUserRegistered",
    "Auth.redirectAfterLogin",
    "Assessment.assessmentDetails",
    "Assessment.assessmentStatus",
    "Assessment.selectTestCart",
    "Assessment.customQuestionCart",
    "Assessment.welcomeVideoPath",
    "Assessment.externalWelcomeVideoLink",
    "Assessment.qualifyingQuestionCart",
    "Assessment.questionLimit",
    "Assessment.optionLimit",
    "Assessment.customizedEmailTemplates",
    "Assessment.assessmentConfig",
    "Workspace.workspaceUrl",
    "Workspace.workspaceName",
    "Workspace.workspaceLogo",
    "Workspace.IntroductionImage",
    "Workspace.WorkspaceFavicon",
    "Workspace.workspaceBrandColor",
    "Workspace.workspaceBrandTextColor",
    "Workspace.workspaceDarkerBrandColor",
    "Workspace.workspaceMidBrandColor",
    "Workspace.workspaceLighterBrandColor",
    "Workspace.workspaceDarkestBrandColor",
    "Workspace.workspaceLightGreyBrandColor",
    "Workspace.subscriptionExpireDate",
    "Workspace.status",
    "Workspace.isTrial",
    "Workspace.daysLeftForTrial",
    "Workspace.trialExtended",
    "Workspace.integrationAddOn",
    "Workspace.whiteLabelAddOn",
    "Workspace.isBillingAllowed",
    "Workspace.isWhiteLabel",
    "Workspace.isFullDiscount",
    "Workspace.isPayPerUsagePlan",
    "Workspace.isLegacyPlan",
    "Workspace.subscriptionName",
    "Workspace.candidateNotificationAllowed",
    "Workspace.isCustomTemplateActive",
    "Workspace.administratorNotificationAllowed",
    "Workspace.isEmailTemplateActive",
    "Workspace.supportEmail",
    "Workspace.termsUrl",
    "Workspace.privacyUrl",
    "Workspace.workspaceId",
    "Global.defaultRegion",
    "Global.isReseller",
    "Global.isDefaultReseller",
    "Global.isPaidUser",
    "Global.locale.defaultLanguage",
    "Global.locale.languageList",
    "Global.ADAcompliance",
    "Global.isAppSumoCouponApplied",
    "Global.sessionId",
    "Global.resellerName",
    "Global.resellerDomain",
    "Global.isDenyCreateWorkspace",
    "Global.showPlansTab",
    "Global.isResellerActive",
    "Global.isSupportLogin",
    "Permission.assessmentCreate",
    "Permission.assessmentDelete",
    "Permission.assessmentArchive",
    "Permission.assessmentCopy",
    "Permission.candidateInvite",
    "Permission.candidateDelete",
    "Permission.settingsUser",
    "Permission.settingsUserAdd",
    "Permission.settingsUserChangeRole",
    "Permission.settingsUserChangeStatus",
    "Permission.settingsRolesAndPermissions",
    "Permission.settingsWhiteLabelPermission",
    "Permission.settingsDeveloperPermission",
    "Permission.billingManageSubscription",
    "Global.ADPSession",
    "Global.websiteFilters",
    "Global.currentUserCountryDialCode",
    "Global.currentUserTimeZone",
    "Global.currentUserCountryCode",
    "Global.isValidCard",
    "Global.unReadNotificationCount",
    "Workspace.enableTrialperiod",
    "Workspace.enableAdvanceProctoring",
    "Workspace.enableWhiteLabel",
]
