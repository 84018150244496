import { useStore } from "@/store"
import { computed } from "vue"
import { useRoute } from "vue-router"

const resellerStagingHosts = ["assessments.join-staging.com"]
export default function useOAuth() {
    let API_DOMAIN = "https://api.testlify.com"
    const SAUDI_ARABIA = "Saudi Arabia"
    const route = useRoute()
    const store = useStore()
    const defaultRegion = computed(() => store.getters.getDefaultRegion)
    let region
    if (defaultRegion.value === "") {
        if (route?.query.region) {
            region = route.query.region === "eu" ? "Europe" : SAUDI_ARABIA
        } else if (localStorage["selectedDataRegion"]) {
            region = localStorage.getItem("selectedDataRegion")
        } else {
            region = "Europe"
        }
    } else {
        region = defaultRegion.value === "EU" ? "Europe" : SAUDI_ARABIA
        localStorage.setItem("selectedDataRegion", region)
        localStorage.setItem("manuallySelectedRegion", "false")
    }

    if (
        window.location.href.includes("tamayaz.ai") ||
        window.location.href.includes("albayanassessment.com") ||
        (!(
            window.location.href.includes("localhost") ||
            window.location.href.includes("vercel")
        ) &&
            region &&
            region.includes(SAUDI_ARABIA))
    ) {
        API_DOMAIN = "https://api-ksa.testlify.com"
    }

    if (
        window.location.href.includes("localhost") ||
        window.location.href.includes("vercel") ||
        resellerStagingHosts.includes(window.location.host)
    ) {
        API_DOMAIN = "https://master.jenkins.testlify.dev"
        // API_DOMAIN = "https://testlify-api-staging-266896172731.europe-west1.run.app"

        // If you need to connect to api branch uncomment and replace the branch id in the below code
        // API_DOMAIN = `https://{branchid}.jenkins.testlify.dev`
        // API_DOMAIN = `https://testlify-api-{branchid}-266896172731.europe-west1.run.app`
    }

    const API_PREFIX = `${API_DOMAIN}/v1`
    return { API_PREFIX }
}
